import React, { useState } from "react";
import { ResourceItem as ResourceItemType } from "../../types/types";
import {
  ChevronRight,
  ChevronDown,
  Folder,
  FileIcon,
  PlusCircle,
  Trash2,
  Edit,
  Database,
  Workflow,
} from "lucide-react";

interface ResourceItemProps {
  item: ResourceItemType;
  depth?: number;
  onDelete: (item: ResourceItemType) => void;
  onEdit: (item: ResourceItemType) => void;
  isSmartOffice?: boolean;
}

const ResourceItem: React.FC<ResourceItemProps> = ({
  item,
  depth = 0,
  onDelete,
  onEdit,
  isSmartOffice = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const hasChildren = item.children && item.children.length > 0;
  const paddingLeft = depth * 20;

  const handleClick = () => {
    if (item.type === "action" && item.action) {
      item.action();
    } else if (hasChildren) {
      setIsExpanded(!isExpanded);
    } else {
      setIsClicked(!isClicked);
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(item);
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit(item);
  };

  const renderIcon = () => {
    if (isSmartOffice) {
      return <Database size={16} />;
    } else if (depth === 1) {
      return <Workflow size={16} />;
    } else if (item.type === "action") {
      return <PlusCircle size={16} />;
    } else {
      return hasChildren ? <Folder size={16} /> : <FileIcon size={16} />;
    }
  };

  return (
    <div className="rs-item-container">
      <div
        className={`rs-item rs-${item.type}`}
        style={{ paddingLeft: `${paddingLeft}px` }}
        onClick={handleClick}
      >
        {hasChildren &&
          (isExpanded ? (
            <ChevronDown size={16} className="rs-chevron" />
          ) : (
            <ChevronRight size={16} className="rs-chevron" />
          ))}
        {renderIcon()}
        <span className="rs-name">{item.name}</span>
        {item.type !== "action" && !isSmartOffice && (
          <>
            <button className="rs-action-btn rs-edit-btn" onClick={handleEdit}>
              <Edit size={16} />
            </button>
            <button
              className="rs-action-btn rs-delete-btn"
              onClick={handleDelete}
            >
              <Trash2 size={16} />
            </button>
          </>
        )}
      </div>
      {isClicked && item.code && item.values && (
        <div className="rs-value">
          <div>
            {item.code} : {item.values.join(", ")}
          </div>
          {item.sample && <div>{item.sample}</div>}
        </div>
      )}
      {isExpanded && hasChildren && (
        <div className="rs-contents">
          {item.children!.map((child) => (
            <ResourceItem
              key={child.id}
              item={child}
              depth={depth + 1}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ResourceItem;
