import React from "react";
import { ResourceItem as ResourceItemType } from "../../types/types";
import ResourceItem from "./ResourceItem";

interface ResourceStructureProps {
  structure: ResourceItemType[];
  onDelete: (item: ResourceItemType) => void;
  onEdit: (item: ResourceItemType) => void;
}

const ResourceStructure: React.FC<ResourceStructureProps> = ({
  structure,
  onDelete,
  onEdit,
}) => {
  return (
    <div className="rs-structure">
      <h2 className="rs-header">{structure[0].name}</h2>
      <div>
        {structure[0].children!.map((item) => (
          <ResourceItem
            key={item.id}
            item={item}
            onDelete={onDelete}
            onEdit={onEdit}
            isSmartOffice={item.name === "SmartOffice"}
          />
        ))}
      </div>
    </div>
  );
};

export default ResourceStructure;
