import React, { useState, useEffect } from "react";
import TextInput from "../../components/Fields/TextInput";
import Dropdown from "../../components/Dropdown/Dropdown";
import Button from "../../components/Button/Button";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import Alert from "../../components/Alert/Alert";
import Integrations from "../../features/Integrations/Integrations";
import getString from "../../utils/getString";
import { COUNTRIES } from "../../constants/countries";
import { DATE_FORMATS, TIME_FORMATS } from "../../constants/time";
import useProfileSettings from "../../hooks/useProfileSettings";
import { useAppContext } from "../../contexts/AppContext";
import moment from "moment-timezone";
import { Alert as AlertType } from "../../types/types";
import { isAlphabet } from "../../utils/validation";
import { updatePlanMessage } from "../../utils/getMessage.tsx";

import "./Profile.css";
import ConnectedAccounts from "../../features/Integrations/ConnectedAccounts";
import InvestorProfileTabs from "../../features/InvestorProfile/InvestorProfileTabs";

const Profile = () => {
  const [names, setNames] = useState({
    firstName: null,
    middleName: null,
    lastName: null,
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedDateFormat, setSelectedDateFormat] = useState(null);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    firstName: false,
    middleName: false,
    lastName: false,
  });
  const [alertProps, setAlertProps] = useState<AlertType>({
    text: "",
    type: "",
  });

  const { settings, saveSettings } = useProfileSettings();
  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    const profileAccess = user?.subscription?.has_profile_access;
    setIsDisabled(!profileAccess);
  }, [user?.subscription?.has_profile_access]);

  useEffect(() => {
    setNames({
      firstName: settings?.first_name,
      middleName: settings?.middle_name,
      lastName: settings?.last_name,
    });
    setSelectedCountry(settings?.country);
    setSelectedTimezone(settings?.user_timezone);
    setSelectedDateFormat(settings?.date_format);
    setSelectedTimeFormat(settings?.time_format);
  }, [settings]);

  const handleSetNames = (type: string, name: string) => {
    setNames((prevState) => ({ ...prevState, [type]: name }));
    if (!isAlphabet(name)) {
      setValidationErrors((prevState) => ({ ...prevState, [type]: true }));
    } else {
      setValidationErrors((prevState) => ({ ...prevState, [type]: false }));
    }
  };

  const timeZones = moment.tz.names().map((zone) => {
    return { name: zone };
  });

  const handleDateFormatSelect = (dateFormat) => {
    setSelectedDateFormat(dateFormat);
  };

  const handleTimeFormatSelect = (timeFormat) => {
    setSelectedTimeFormat(timeFormat);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  const handleTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
  };

  const handleSave = () => {
    saveSettings({
      first_name: names["firstName"],
      middle_name: names["middleName"],
      last_name: names["lastName"],
      full_name: `${names["firstName"]} ${names["middleName"]} ${names["lastName"]}`,
      country: selectedCountry,
      user_timezone: selectedTimezone,
      date_format: selectedDateFormat,
      time_format: selectedTimeFormat,
    })
      .then(() => {
        setAlertProps({ text: getString("changesSaved"), type: "success" });
      })
      .catch((e) => {
        setAlertProps({
          text: getString("errorOccured"),
          type: "error",
        });
      });
  };

  return (
    <div className="profile-wrapper">
      {alertProps.text && <Alert {...alertProps} />}
      <h4 className="settings-subheader">Account details</h4>
      <h2 className="settings-header">Profile</h2>
      <div className="settings-input-container">
        <div className="settings-input-inner-container">
          <TextInput
            width="250px"
            label="First Name"
            data-testid="name"
            value={names["firstName"] ?? ""}
            onChange={(e) => handleSetNames("firstName", e.target.value)}
            align="column"
          />
          {validationErrors["firstName"] && (
            <div className="validation-error">
              Can only contain letters [a-z][A-Z]
            </div>
          )}
        </div>
        <div className="settings-input-inner-container">
          <TextInput
            width="250px"
            label="Middle Name"
            data-testid="name"
            value={names["middleName"] ?? ""}
            onChange={(e) => handleSetNames("middleName", e.target.value)}
            align="column"
          />
          {validationErrors["middleName"] && (
            <div className="validation-error">
              Can only contain letters [a-z][A-Z]
            </div>
          )}
        </div>
        <div className="settings-input-inner-container">
          <TextInput
            width="250px"
            label="Last Name"
            data-testid="name"
            value={names["lastName"] ?? ""}
            onChange={(e) => handleSetNames("lastName", e.target.value)}
            align="column"
          />
          {validationErrors["lastName"] && (
            <div className="validation-error">
              Can only contain letters [a-z][A-Z]
            </div>
          )}
        </div>
      </div>
      <div className="profile-user-selections-wrapper">
        <div className="profile-user-selections">
          <div>
            <div className="settings-item-label">Date Format</div>
            <Dropdown
              displayOption="name"
              options={DATE_FORMATS}
              onSelect={handleDateFormatSelect}
              placeholder="Select a date format..."
              selectedValue={selectedDateFormat}
            />
          </div>
          <div>
            <div className="settings-item-label">Time Format</div>
            <Dropdown
              displayOption="name"
              options={TIME_FORMATS}
              onSelect={handleTimeFormatSelect}
              placeholder="Select a time format..."
              selectedValue={selectedTimeFormat}
            />
          </div>
        </div>
        <div className="profile-user-selections">
          <div>
            <div className="settings-item-label">Country</div>
            <Dropdown
              displayOption="name"
              options={COUNTRIES}
              onSelect={handleCountrySelect}
              placeholder="Choose a country..."
              selectedValue={selectedCountry}
            />
          </div>
          <div>
            <div className="settings-item-label">Time Zone</div>
            <Dropdown
              displayOption="name"
              options={timeZones}
              onSelect={handleTimezoneSelect}
              placeholder="Choose a timezone..."
              selectedValue={selectedTimezone}
            />
          </div>
        </div>
      </div>
      <div className="settings-actions">
        {user && (
          <Tooltip
            position="right"
            isHidden={!isDisabled}
            text={updatePlanMessage(getString("profileAccess"), user)}
            minWidth="200px"
          >
            <Button
              color="Secondary"
              size="Small"
              onClick={handleSave}
              disabled={
                validationErrors["firstName"] === true ||
                validationErrors["middleName"] === true ||
                validationErrors["lastName"] === true ||
                isDisabled
              }
            >
              Save changes
            </Button>
          </Tooltip>
        )}
      </div>

      <div className="profile-integrations">
        {user?.role == "advisor" ? (
          <Integrations setAlertProps={setAlertProps} />
        ) : (
          <ConnectedAccounts />
        )}
      </div>
      {user?.role == "retail" && (
        <InvestorProfileTabs investorId={user.investor_id} />
      )}
    </div>
  );
};

export default Profile;
