import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser } from "../../api/api";
import { Eye, EyeOff } from "lucide-react";
import "./AccountType.css";

const TYPING_SPEED = 40;

const useTypingEffect = (text, speed = TYPING_SPEED, start = true) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  useEffect(() => {
    if (!start || !text) return;

    setIsTypingComplete(false);

    let index = displayedText.length;
    let intervalId = null;

    const type = () => {
      if (index < text.length) {
        setDisplayedText(text.slice(0, index + 1));
        index += 1;
      } else {
        clearInterval(intervalId);
        setIsTypingComplete(true);
      }
    };

    intervalId = setInterval(type, speed);

    return () => {
      clearInterval(intervalId);
    };
  }, [text, speed, start]);

  return [displayedText, isTypingComplete];
};

const TypingText = ({
  text,
  speed = TYPING_SPEED,
  className,
  onComplete,
  start = true,
}) => {
  const [displayedText, isTypingComplete] = useTypingEffect(text, speed, start);

  useEffect(() => {
    if (isTypingComplete && onComplete) {
      onComplete();
    }
  }, [isTypingComplete, onComplete]);

  return start ? <p className={className}>{displayedText}</p> : null;
};

const BriefcaseIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
  </svg>
);

const UserIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

const PasswordInput = ({ value, onChange, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="fin-password-input-wrapper">
      <input
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="fin-input fin-password-input"
      />
      <button
        type="button"
        className="fin-password-toggle"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
};

const AccountType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [startInitialTyping, setStartInitialTyping] = useState(false);
  const [showIntroText, setShowIntroText] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [showNameInputField, setShowNameInputField] = useState(false);
  const [showGreeting, setShowGreeting] = useState(false);
  const [showEmailPasswordPrompt, setShowEmailPasswordPrompt] = useState(false);
  const [showEmailPasswordInputs, setShowEmailPasswordInputs] = useState(false);
  const [showAccountSetupMessage, setShowAccountSetupMessage] = useState(false);
  const [showAccountQuestion, setShowAccountQuestion] = useState(false);
  const [showAccountButtons, setShowAccountButtons] = useState(false);
  const [stripePricing, setStripePricing] = useState("");
  const [error, setError] = useState("");
  const [showWrapper, setShowWrapper] = useState(false);
  const phoneNumber = location.state?.phoneNumber || null;

  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);

  const introText = "I am your AI-powered digital financial analyst.";

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartInitialTyping(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWrapper(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showNameInputField && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [showNameInputField]);

  useEffect(() => {
    if (showEmailPasswordInputs && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [showEmailPasswordInputs]);

  const handleNameSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      setShowNameInputField(false);
      setShowGreeting(true);
    }
  };

  const handleEmailPasswordSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (!email.trim() || !password.trim() || !confirmPassword.trim()) {
      setError("Please enter email, password, and confirm your password.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    createUser(email, password, name, phoneNumber)
      .then((response) => {
        // if user already exists and has subscription
        if (response?.response?.status === 400) {
          navigate("/auth#login/verify?account_exists=true");
          return;
        }
        setStripePricing(response.data.stripe_pricing);
        setShowEmailPasswordPrompt(false);
        setShowEmailPasswordInputs(false);
        setShowAccountSetupMessage(true);
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        setError(
          "An error occurred while creating your account. Please try again.",
        );
      });
  };

  const handleBusinessPricing = () => {
    navigate("/signup/account-type/business", {
      state: { email, password, name, stripePricing },
    });
  };

  const handlePersonalPricing = () => {
    navigate("/signup/account-type/personal", {
      state: { email, password, name, stripePricing },
    });
  };

  const splitName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    const firstName = nameParts[0];
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
    return { firstName, lastName };
  };

  const { firstName } = splitName(name);

  return (
    <div className="fin-onboarding-account-container">
      {showWrapper && (
        <div className="fin-onboarding-account-wrapper">
          <div className="fin-onboarding-account-items">
            <TypingText
              key="finTitle"
              text="Hi, I'm Fin!"
              className="fin-title"
              onComplete={() => setShowIntroText(true)}
              start={startInitialTyping}
            />
            <TypingText
              key="introText"
              text={introText}
              className="fin-text"
              onComplete={() => setShowNameInput(true)}
              start={showIntroText}
            />

            {!showGreeting && (
              <>
                <TypingText
                  key="knowEachOther"
                  text="To get your account set up, can you tell me your first and last name?"
                  className="fin-text"
                  onComplete={() => setShowNameInputField(true)}
                  start={showNameInput}
                />

                {showNameInputField && (
                  <div className="fin-form-container">
                    <form onSubmit={handleNameSubmit} className="fin-name-form">
                      <input
                        type="text"
                        placeholder="Enter your full name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="fin-input"
                        ref={nameInputRef}
                      />
                      <button type="submit" className="fin-submit-button">
                        Submit
                      </button>
                    </form>
                  </div>
                )}
              </>
            )}

            <TypingText
              key={`greet-${name}`}
              text={`It is a pleasure to meet you, ${firstName}. I am excited to start working together!`}
              className="fin-text"
              onComplete={() => setShowEmailPasswordPrompt(true)}
              start={showGreeting}
            />

            {showEmailPasswordPrompt && !showAccountSetupMessage && (
              <TypingText
                key="emailPasswordPrompt"
                text="Please provide an email and password to keep your account safe and secure."
                className="fin-text"
                onComplete={() => setShowEmailPasswordInputs(true)}
                start={showEmailPasswordPrompt}
              />
            )}

            {showEmailPasswordInputs && !showAccountSetupMessage && (
              <div className="fin-form-container">
                <form onSubmit={handleEmailPasswordSubmit}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="fin-input"
                    ref={emailInputRef}
                  />
                  <PasswordInput
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                  />
                  <PasswordInput
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your password"
                  />
                  <button type="submit" className="fin-submit-button">
                    Submit
                  </button>
                  {error && <p className="fin-error-message">{error}</p>}
                </form>
              </div>
            )}

            {showAccountSetupMessage && (
              <TypingText
                key="accountSetupMessage"
                text="Thanks, just one more thing."
                className="fin-text"
                onComplete={() => setShowAccountQuestion(true)}
                start={showAccountSetupMessage}
              />
            )}

            {showAccountQuestion && (
              <TypingText
                key="businessOrPersonal"
                text="Are you using Q(Fin) for business or personal?"
                className="fin-text"
                onComplete={() => setShowAccountButtons(true)}
                start={showAccountQuestion}
              />
            )}

            {showAccountButtons && (
              <div className="fin-button-group">
                <button className="fin-button" onClick={handleBusinessPricing}>
                  <div className="fin-button-content">
                    <span className="fin-button-title">Business</span>
                    <div className="fin-button-details">
                      <BriefcaseIcon className="fin-button-icon" />
                      <p className="fin-button-description">
                        Get AI-ready to elevate productivity and grow your
                        business.
                      </p>
                    </div>
                  </div>
                </button>
                <button className="fin-button" onClick={handlePersonalPricing}>
                  <div className="fin-button-content">
                    <span className="fin-button-title">Personal</span>
                    <div className="fin-button-details">
                      <UserIcon className="fin-button-icon" />
                      <p className="fin-button-description">
                        Learn, research, and optimize your personal investments.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountType;
