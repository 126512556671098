import React from "react";
import Modal from "../../components/Modal/Modal.tsx";
import { Message } from "../../types/types.ts";

import "./chatwindow.css";

const RenderValue = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <table className="table">
        <thead>
          <tr>
            {Object.keys(value[0] || {}).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {value.map((item, index) => (
            <tr key={index}>
              {Object.entries(item).map(([key, val]) => (
                <td key={key}>
                  {typeof val === "object" && val !== null ? (
                    <RenderValue value={val} />
                  ) : (
                    val?.toString()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else if (typeof value === "object" && value !== null) {
    return <RenderObject obj={value} />;
  } else {
    return <span>{value?.toString()}</span>;
  }
};

const RenderObject = ({ obj }) => {
  return (
    <ul
      style={{
        marginBottom: "10px",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      {Object.entries(obj).map(([key, value]) => (
        <li className="render-object" key={key}>
          <strong>{key}:</strong> <RenderValue value={value} />
        </li>
      ))}
    </ul>
  );
};

const RenderResult = ({ result }) => {
  if (Array.isArray(result)) {
    return result.map((item, index) => {
      return (
        <div key={index}>
          <RenderObject obj={item} />
        </div>
      );
    });
  } else if (typeof result === "object" && result !== null) {
    return <RenderObject obj={result} />;
  } else {
    return <div>Invalid result type</div>;
  }
};

const VerificationDataComponent = ({ verificationData }) => {
  return (
    <>
      {verificationData && (
        <div>
          {verificationData.results.map((item, index) => (
            <div key={index}>
              <RenderResult result={item} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

interface ChatValidationModalProps {
  message: Message;
  setValidationOpen: (isOpen: boolean) => void;
  isValidationOpen: boolean;
}

const ChatValidationModal = ({
  message,
  isValidationOpen,
  setValidationOpen,
}: ChatValidationModalProps) => {
  const handleValidationClose = () => {
    setValidationOpen(false);
  };

  const functionValue = message.verification_data?.[0]?.function;

  return (
    <Modal
      isOpen={isValidationOpen}
      onClose={handleValidationClose}
      closeOnClickOutside
      hasCloseButton
      width={600}
    >
      <VerificationDataComponent verificationData={message.verification_data} />
    </Modal>
  );
};

export default ChatValidationModal;
