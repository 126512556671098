import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Pendo from "../../components/Pendo/Pendo.tsx";
import { Carousel } from "../../components/Carousel/Carousel.tsx";
import { logout } from "../../utils/shared.ts";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough.tsx";
import { useAppContext } from "../../contexts/AppContext.tsx";
import "./Sidebar.css";
import FinStatusIndicator from "../../components/FinStatusIndicator/FinStatusIndicator.tsx";
import HelpPopup from "../../components/Help/HelpPopup.tsx";
import useFetchHelp from "../../hooks/useFetchHelp";
import {
  ArrowLeftFromLine,
  LogOut,
  LifeBuoy,
  ChartArea,
  Rss,
  Settings,
  ArrowUpCircle,
  UserCircle,
  Server,
  ClipboardList,
} from "lucide-react";

const ACTIVITY_LINKS = [
  { label: "Activity", path: "/u/app/fin/activity", icon: "globe-outline" },
  { label: "Dashboard", path: "/u/app/home", icon: "bar-chart-outline" },
  {
    label: "Assignments",
    path: "/u/app/fin/assignments",
    icon: "clipboard-outline",
  },
];

const FIN_LINKS = [
  {
    label: "Settings",
    path: "/u/app/settings/fin-settings",
    icon: "cog-outline",
  },
  {
    label: "Upload/Files",
    path: "/u/app/fin/upload",
    icon: "arrow-up-circle-outline",
  },
];

const SETTING_LINKS = [
  {
    label: "Profile",
    path: "/u/app/settings/profile",
    icon: "person-circle-outline",
  },
  {
    label: "Fin Settings",
    path: "/u/app/settings/fin-settings",
    icon: "cog-outline",
  },
  {
    label: "Data Controls",
    path: "/u/app/settings/data-controls",
    icon: "server-outline",
  },
];

const CONFIG = {
  "/u": ACTIVITY_LINKS,
  "/u/app/fin/activity": ACTIVITY_LINKS,
  "/u/app/home": ACTIVITY_LINKS,
  "/u/app/fin/assignments": ACTIVITY_LINKS,
  "/u/app/settings/fin-settings": FIN_LINKS,
  "/u/app/fin/upload": FIN_LINKS,
  "/u/app/fin/files": FIN_LINKS,
  "/u/app/investors": [],
  "/u/app/chat": [],
  "/u/app/settings/profile": SETTING_LINKS,
  "/u/app/settings/data-controls": SETTING_LINKS,
};

const Sidebar = () => {
  const {
    state: { selectedRoute, user },
    actions: { setSelectedRoute },
  } = useAppContext();

  const [isExpanded, setIsExpanded] = useState(true);
  const [isHelpPopupOpen, setIsHelpPopupOpen] = useState(false);
  const [helpContent, setHelpContent] = useState(null);

  const toggleSidebar = () => setIsExpanded(!isExpanded);
  const navigate = useNavigate();
  const location = useLocation();

  if (user?.subscription?.has_data_mapping_access) {
    if (ACTIVITY_LINKS[ACTIVITY_LINKS.length - 1].label != "Data Map") {
      ACTIVITY_LINKS.push({
        label: "Data Map",
        path: "/u/app/fin/data-mapping",
        icon: "globe-outline",
      });
    }
  }

  const {
    currentStep,
    refs,
    nextStep,
    prevStep,
    isActive,
    setIsActive,
    handleClose,
    registerRef,
    unregisterRef,
    activeSlide,
  } = useWalkthrough();

  const stepId = "fin";
  const ref = useRef(null);

  const toggleHelpPopup = () => {
    setIsHelpPopupOpen(!isHelpPopupOpen);
  };

  const helpType = location.pathname.split("/").filter(Boolean).pop();
  const { help } = useFetchHelp({ helpType });

  useEffect(() => {
    if (help.content) {
      setHelpContent({ content: help.content });
    } else {
      setHelpContent(null);
    }
  }, [help.content]);

  useEffect(() => {
    if (currentStep === 5) {
      setSelectedRoute("/u/app/settings/fin-settings");
    }
    if (currentStep === 4) {
      setSelectedRoute("/u/app/fin/activity");
    }
  }, [currentStep, setSelectedRoute]);

  useEffect(() => {
    if (isActive && location) {
      navigate(location.pathname, { replace: true });
    }
  }, [navigate, location, isActive]);

  useEffect(() => {
    registerRef(stepId, ref);
    return () => unregisterRef(stepId);
  }, [registerRef, unregisterRef, stepId]);

  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  const renderSidebarItem = (item, index) => {
    const active = isLinkActive(item.path);
    const className = `sidebar-link ${active ? "active" : ""}`;

    if (item.onClick) {
      // For static items like Help and Logout
      return (
        <li key={index}>
          <button
            className={className}
            onClick={item.onClick}
            aria-label={item.label}
          >
            <ion-icon name={item.icon}></ion-icon>
            {isExpanded && <span className="text">{item.label}</span>}
          </button>
        </li>
      );
    }

    // For dynamic items like Activity, Dashboard, etc.
    return (
      <li key={index}>
        <Link className={className} to={item.path}>
          <ion-icon name={item.icon}></ion-icon>
          {isExpanded && <span className="text">{item.label}</span>}
        </Link>
      </li>
    );
  };

  const staticItems = [
    ...(helpContent
      ? [
          {
            label: "Help",
            icon: "help-circle-outline",
            onClick: toggleHelpPopup,
          },
        ]
      : []),
    { label: "Logout", icon: "log-out-outline", onClick: logout },
  ];

  return (
    <>
      <div
        className={`sidebar ${isExpanded ? "expanded" : "collapsed"} ${
          isActive ? "active" : ""
        }`}
      >
        <div className="sidebar-control-btn">
          <button onClick={toggleSidebar} aria-label="Toggle Sidebar">
            {isExpanded ? (
              <ArrowLeftFromLine size={14} color="#808080FF" />
            ) : (
              <ArrowLeftFromLine
                size={14}
                color="#808080FF"
                style={{ transform: "rotate(180deg)" }}
              />
            )}
          </button>
        </div>
        <div className="sidebar-items-wrapper">
          {CONFIG[selectedRoute] && (
            <div className={`sidebar-links ${isActive ? "active" : ""}`}>
              {CONFIG[selectedRoute] === FIN_LINKS && (
                <FinStatusIndicator full={isExpanded} />
              )}
              <ul>{CONFIG[selectedRoute].map(renderSidebarItem)}</ul>
            </div>
          )}
          <div className="sidebar-static-items">
            <ul>{staticItems.map(renderSidebarItem)}</ul>
          </div>
        </div>
      </div>
      {helpContent && helpContent.content && (
        <HelpPopup
          isOpen={isHelpPopupOpen}
          onClose={toggleHelpPopup}
          helpContent={helpContent}
        />
      )}
    </>
  );
};

export default Sidebar;
