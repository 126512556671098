import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import TypingEffect from "../TypingEffect/TypingEffect.tsx";
import ChatBubbleActions from "./ChatBubbleActions.tsx";
import ChatValidationModal from "./ChatValidationModal.tsx";
import { Message } from "../../types/types.ts";
import { saveFeedback } from "../../api/api.ts";
import ChatFeedbackModal from "./ChatFeedbackModal.tsx";
import LinkedinModal from "../Social/LinkedinModal.tsx";

import moment from "moment";

import "./chatwindow.css";

const DEFAULT_OPTIONS = {
  "Too long": false,
  "Too short": false,
  Inaccurate: false,
  "Not helpful": false,
  "Out of date": false,
  "Harmful or offensive": false,
};

function isResultValid(value) {
  if (value == null) {
    return false;
  }

  if (typeof value === "string") {
    return value.length > 0;
  }
  return true;
}

const MESSAGE_TYPE = {
  GENERATED: "generated",
  USER: "user",
};

interface ChatBubbleProps {
  message: Message;
  index: number;
  messagesLength: number;
}

const ChatBubble = ({ message, index, messagesLength }: ChatBubbleProps) => {
  const formattedTime = moment(message.submitted_at).format("h:mm a");
  const [showActions, setShowActions] = useState(false);
  const [isLinkedinModalOpen, setIsLinkedinModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [customFeedback, setCustomFeedback] = useState("");
  const [isValidationOpen, setValidationOpen] = useState(false);
  const [actionsSelected, setActionsSelected] = useState({
    negative: false,
    positive: false,
  });
  const [feedbackOptions, setFeedbackOptions] = useState(DEFAULT_OPTIONS);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (selectedMessage) {
      localStorage.setItem("selectedMessageId", selectedMessage.id);
    }
  }, [selectedMessage]);

  useEffect(() => {
    const selectedMessageId = localStorage.getItem("selectedMessageId");
    if (selectedMessageId && message.id === Number(selectedMessageId)) {
      setIsLinkedinModalOpen(true);
    }
  }, []);

  const checkValidation = (verificationData) => {
    const isArray = Array.isArray(verificationData?.results);
    const isValid =
      isArray &&
      verificationData?.results?.every((item) => {
        return isResultValid(item);
      });
    setIsValid(isValid);
  };

  useEffect(() => {
    if (message.verification_data) {
      checkValidation(message.verification_data);
    }
  }, [message.verification_data]);

  useEffect(() => {
    setActionsSelected({
      negative:
        message.feedback?.category === "negative" &&
        message.feedback?.is_active === true,
      positive:
        message.feedback?.category === "positive" &&
        message.feedback?.is_active === true,
    });
  }, [message]);

  const updateUserFeedback = (e) => {
    const category = e.target.id;

    if (category === "negative" && !actionsSelected[category]) {
      setShowFeedbackModal(true);
    }
    setActionsSelected((prevState) => {
      const newState = { ...prevState };
      if (prevState[category]) {
        newState[category] = false;
        setFeedbackOptions(DEFAULT_OPTIONS);
        setCustomFeedback("");
      } else {
        const oppositeAction =
          category === "positive" ? "negative" : "positive";
        if (prevState[oppositeAction]) {
          newState[oppositeAction] = false;
          setFeedbackOptions(DEFAULT_OPTIONS);
          setCustomFeedback("");
        }
        newState[category] = true;
      }

      return newState;
    });

    saveFeedback({
      category: category,
      chat_id: message.id,
      is_active: !actionsSelected[category],
    });
  };

  return (
    <div
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      key={index}
      className={`chat-bubble ${message.type} ${
        index === messagesLength - 1 ? "last-message" : ""
      }`}
      data-testid={index === messagesLength - 1 ? "lastChatMessage" : ""}
    >
      {message.message_type === MESSAGE_TYPE.GENERATED &&
      messagesLength === 1 ? (
        <div className="typing-effect-wrapper">
          <TypingEffect
            text={message.text}
            typingSpeed={20}
            onComplete={() => null}
          />
        </div>
      ) : (
        <ReactMarkdown>{message.text}</ReactMarkdown>
      )}

      <div className="chat-bubble-meta">
        {showActions &&
        message.type === "received" &&
        message.text !== "Loading..." ? (
          <ChatBubbleActions
            setSelectedMessage={setSelectedMessage}
            setIsLinkedinModalOpen={setIsLinkedinModalOpen}
            actionsSelected={actionsSelected}
            updateUserFeedback={updateUserFeedback}
            message={message}
            messageText={message.text}
            setValidationOpen={setValidationOpen}
            isValid={isValid}
          />
        ) : (
          <div className="chat-bubble-time">{formattedTime}</div>
        )}
        <ChatValidationModal
          message={message}
          setValidationOpen={setValidationOpen}
          isValidationOpen={isValidationOpen}
        />
        <ChatFeedbackModal
          message={message}
          customFeedback={customFeedback}
          setCustomFeedback={setCustomFeedback}
          setShowActions={setShowActions}
          setShowFeedbackModal={setShowFeedbackModal}
          showFeedbackModal={showFeedbackModal}
          feedbackOptions={feedbackOptions}
          setFeedbackOptions={setFeedbackOptions}
        />
        <LinkedinModal
          text={message.text}
          setIsLinkedinModalOpen={setIsLinkedinModalOpen}
          isLinkedinModalOpen={isLinkedinModalOpen}
        />
      </div>
    </div>
  );
};

export default ChatBubble;
