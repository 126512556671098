import React, { useEffect, useState } from "react";
import Tabs from "../../components/Tabs/Tabs";
import Upload from "../../pages/fin/Upload.tsx";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import InvestorProfileDataset from "../../features/InvestorProfile/InvestorProfileDataset";
import { useAppContext } from "../../contexts/AppContext";
import getString from "../../utils/getString";
import { updatePlanMessage } from "../../utils/getMessage.tsx";
import "./InvestorProfileTabs.css";

const InvestorProfileTabs = ({ investorId }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    const investorProfileAccess =
      user?.subscription?.has_investor_profile_access;
    setIsDisabled(!investorProfileAccess);
  }, [user?.subscription?.has_investor_profile_access]);

  return (
    <Tabs
      tabs={[
        {
          label: "Estate Planning",
          content: (
            <InvestorProfileDataset
              key="estate_planning"
              investorId={investorId}
              datasetId="estate_planning"
            />
          ),
        },
        {
          label: "Financial Information",
          content: (
            <InvestorProfileDataset
              key="financial_information"
              investorId={investorId}
              datasetId="financial_information"
            />
          ),
        },
        {
          label: "Investment Profile",
          content: (
            <InvestorProfileDataset
              key="investment_profile"
              investorId={investorId}
              datasetId="investment_profile"
            />
          ),
        },
        {
          label: "Legal Regulatory",
          content: (
            <InvestorProfileDataset
              key="legal_regulatory"
              investorId={investorId}
              datasetId="legal_regulatory"
            />
          ),
        },
        {
          label: "Liquidity Needs",
          content: (
            <InvestorProfileDataset
              key="liquidity_needs"
              investorId={investorId}
              datasetId="liquidity_needs"
            />
          ),
        },
        {
          label: "File Upload",
          content: (
            <div>
              {user && (
                <Tooltip
                  position="top"
                  isHidden={!isDisabled}
                  text={updatePlanMessage(
                    getString("investorProfileAccess"),
                    user,
                  )}
                  minWidth="200px"
                >
                  {!user?.subscription?.has_investor_profile_access && (
                    <div className="feature-disabled"></div>
                  )}
                  <Upload
                    investorId={investorId}
                    showDownload={true}
                    showDelete={true}
                    showAddToFin={true}
                  />
                </Tooltip>
              )}
            </div>
          ),
        },
      ]}
    />
  );
};

export default InvestorProfileTabs;
