import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "../IconButton/IconButton";

import "./Copy.css";

interface CopyProps {
  text: string;
}

const Copy = ({ text }: CopyProps) => {
  const [copySuccess, setCopySuccess] = useState("");
  const removeMarkdown = (text: string): string => {
    // Function to preserve list markers
    const preserveListMarkers = (line: string): string => {
      const unorderedMatch = line.match(/^(\s*[-*+]\s)/);
      const orderedMatch = line.match(/^(\s*\d+\.\s)/);
      return unorderedMatch ? unorderedMatch[1] + line.slice(unorderedMatch[1].length) :
          orderedMatch ? orderedMatch[1] + line.slice(orderedMatch[1].length) :
              line;
    };

    // Split the text into lines
    let lines = text.split('\n');

    // Process each line
    lines = lines.map(line => {
      // Remove headers, bold, italic, links, code blocks, strikethrough
      line = line.replace(/#{1,6}\s?/g, '')
          .replace(/(\*\*|__)(.*?)\1/g, '$2')
          .replace(/(\*|_)(.*?)\1/g, '$2')
          .replace(/\[(.*?)\]\(.*?\)/g, '$1')
          .replace(/`{1,3}.*?`{1,3}/g, '')
          .replace(/~~(.*?)~~/g, '$1');

      // Preserve list markers and indentation
      line = preserveListMarkers(line);

      // Remove blockquotes markers but preserve the text
      line = line.replace(/^\s*>\s?/g, '');

      return line;
    });

    // Join the lines back together
    return lines.join('\n').trim();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(removeMarkdown(text)).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      (err) => {
        setCopySuccess("Failed to copy!");
        console.error("Could not copy text: ", err);
      },
    );
  };

  return (
    <div className="copy">
      <div className="copy-inner">
        <div className="copy-success">
          {copySuccess && (
            <span style={{ marginLeft: "10px" }}>{copySuccess}</span>
          )}
        </div>
        <IconButton onClick={handleCopy}>
          <ion-icon name="clipboard-outline"></ion-icon>
        </IconButton>
      </div>
    </div>
  );
};

export default Copy;
