import React from "react";
import { formatCurrency, getNumberState } from "../../utils/format";
import IconButton from "../../components/IconButton/IconButton.tsx";
import { useNavigate } from "react-router-dom";
import "./InvestorCard.css";
import { format } from "date-fns";

const InvestorCard = ({ item, setSelectedInvestor, setOpenNotes }) => {
  const navigate = useNavigate();

  const handleClickInvestor = () => {
    navigate(`/u/app/investors/${item.id}`);
  };

  const handleNotesClick = (e) => {
    e.stopPropagation();
    setSelectedInvestor(item);
    setOpenNotes(true);
  };

  const ytd_performance = item.current_metrics?.ytd_change;
  const one_day_performance = item.current_metrics?.one_day_change;
  const last_updated = item.current_metrics?.last_updated;
  const sharpe_ratio = item.current_metrics?.sharpe_ratio;
  const total_aum = Number(item.current_metrics?.aum);
  const formatted_total_aum =
    total_aum != null && !isNaN(total_aum)
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(total_aum)
      : null;
  const showSharpeRatio = Number(sharpe_ratio) > -4 && Number(sharpe_ratio) < 4;


  return (
    <div className="investor-card" onClick={handleClickInvestor}>
      <div className="investor-name">{item.name}</div>
      <div className="investor-card-field">
        {formatted_total_aum && (
          <div className="investor-card-aum-container">
            <div className="investor-card-aum">
              <div className="investor-card-item-label">AUM</div>
              <div>
                {one_day_performance && (
                  <div className="investor-card-aum-change">
                    <div
                      className={`investor-card-aum-percentage ${getNumberState(
                        one_day_performance,
                      )}`}
                    >
                      {one_day_performance?.toFixed(2)}%
                    </div>
                    <div>(1-Day)</div>
                  </div>
                )}
              </div>
            </div>
            <div className="investor-card-item">{formatted_total_aum}</div>
          </div>
        )}
      </div>
      <div className="investor-card-section investor-card-field">
        {ytd_performance && (
          <div>
            <div className="investor-card-ytd-perf">
              <div className="investor-card-item-label">YTD Perf.</div>
              <div className="investor-card-as-of">(as of {last_updated})</div>
            </div>
            <div
              className={`investor-card-item ${getNumberState(
                ytd_performance,
              )}`}
            >
              {ytd_performance?.toFixed(2)}%
            </div>
          </div>
        )}
        {sharpe_ratio && showSharpeRatio && (
          <div className="investor-card-sharpe">
            <div className="investor-card-item-label">Sharpe</div>
            <div className="investor-card-item">
              {Number(sharpe_ratio)?.toFixed(2)}
            </div>
          </div>
        )}
      </div>
      <div className="investor-card-section">
        {item.orion_created_date && (
          <div>
            <div className="investor-card-item-label">Account Opened</div>
            <div>
              {format(new Date(item.orion_created_date), "MMMM d, yyyy")}
            </div>
          </div>
        )}
        <div className="investor-card-notes">
          <IconButton onClick={(e) => handleNotesClick(e)}>
            <ion-icon
              size="large"
              style={{ fill: "blue" }}
              name="document-text"
            ></ion-icon>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default InvestorCard;
