export const sortOptions = [
  { value: "None", label: "None" },
  { value: "investor_name", label: "Investor" },
  { value: "portfolio_name", label: "Portfolio" },
  { value: "next_run_date", label: "Next Run Date" },
  { value: "frequency", label: "Frequency" },
  // { value: "document_type", label: "Document Type" },
];

export const assignmentTableHeaders = [
  "investor",
  "portfolio",
  "assignment",
  "assigmentRunDate",
  "assignmentFrequency",
  "assignmentDocumentType",
];

export const initialFilters = {
  investor: null,
  portfolio: null,
  frequency: null,
  // document_type: null,
  next_run_date: null,
};

export const filterConfig = [
  { name: "investor_name", type: "select" },
  { name: "portfolio_name", type: "select" },
  { name: "frequency", type: "select" },
  // { name: "document_type", type: "select" },
  { name: "next_run_date", type: "select" },
];

export const getInvestorOptions = (list) => {
  const investors = [...new Set(list.map((item) => item.investor_name))];
  return investors.map((investor) => ({
    value: investor,
    label: investor,
  }));
};

export const getPortfolioOptions = (list) => {
  const portfolios = [...new Set(list.map((item) => item.portfolio_name))];
  return portfolios.map((portfolio) => ({
    value: portfolio,
    label: portfolio,
  }));
};

export const getRunDateOptions = (list) => {
  const nextRunDates = [...new Set(list.map((item) => item.next_run_date))];
  return nextRunDates.map((date) => ({ value: date, label: date }));
};

export const STATIC_FILTERS = [
  {
    name: "frequency",
    label: "Frequency",
    type: "select",
    options: [
      { value: "Daily", label: "Daily" },
      { value: "Weekly", label: "Weekly" },
      { value: "Monthly", label: "Monthly" },
      { value: "Quarterly", label: "Quarterly" },
    ],
  },
  // {
  //   name: "document_type",
  //   label: "Document Type",
  //   type: "select",
  //   options: [
  //     { value: "Word Document", label: "Word Document" },
  //     { value: "PDF", label: "PDF" },
  //   ],
  // },
];
