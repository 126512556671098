import React, { useEffect, useState } from "react";
import {
  postAssignment,
  getAssignments,
  getUsage,
  putAssignment,
} from "../api/api";
import { Assignment } from "../types/types";
import axios from "axios";
import { useAppContext } from "../contexts/AppContext";

const useAssignments = () => {
  const [assignments, setAssignments] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    actions: { setErrorInfo, setUsage },
  } = useAppContext();

  const refresh = async () => {
    getAssignments()
      .then((data) => {
        setAssignments(data);
        setFilteredAssignments(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  const createAssignment = (assignment: Assignment) => {
    postAssignment(assignment).then((response) => {
      getUsage().then((response) => setUsage(response));
      if (!response.ok) {
        setErrorInfo({ errorType: response.message, type: "assignments" });
      }
      refresh();
    });
  };

  const updateAssignment = (assignmentId: number, assignment: Assignment) => {
    putAssignment(assignmentId, assignment).then((response) => {
      if (!response.ok) {
        setErrorInfo({ errorType: response.message, type: "assignments" });
      }
      refresh();
    });
  };

  const deleteAssignment = (assignment: Assignment) => {
    if (assignment.id) {
      axios
        .delete(`/api/fin/assignment/${assignment.id}`)
        .then(() => refresh());
    }
  };

  return {
    filteredAssignments,
    isLoading,
    error,
    createAssignment,
    updateAssignment,
    deleteAssignment,
    refresh,
    assignments,
  };
};

export default useAssignments;
