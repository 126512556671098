import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./StripePricingTable.css";

const StripePricingTable = ({ type, stripePricing }) => {
  const location = useLocation();
  const tableId = stripePricing[type];
  const publishableKey = stripePricing.pricing_table_publishable_id;
  const email = location.state?.email || null;

  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://js.stripe.com/v3/pricing-table.js"]',
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="stripe-table-container">
      <stripe-pricing-table
        pricing-table-id={tableId}
        publishable-key={publishableKey}
        customer-email={email}
      ></stripe-pricing-table>
    </div>
  );
};

export default StripePricingTable;
