import React, { useState } from "react";
import "./DataMapping.css";
import {
  DataMapping as DataMappingType,
  ResourceItem as ResourceItemType,
} from "../../types/types";
import ResourceStructure from "../../features/DataMapping/ResourceStructure";
import useDataMapping from "../../hooks/useDataMapping";

const DataMapping: React.FC = () => {
  const { mappings, createMapping, deleteMapping, updateMapping } =
    useDataMapping();

  const SmartOfficeDictionary = {
    Policy: {
      Age: {},
      AllPartiesWithRel: {},
      AlphaNum1: {},
      AlphaNum2: {},
      AlphaNum20: {},
      ALPHANUM10: {},
      ALPHANUM11: {},
      ALPHANUM12: {},
      ALPHANUM13: {},
      ALPHANUM14: {},
      ALPHANUM15: {},
      ALPHANUM16: {},
      ALPHANUM17: {},
      ALPHANUM18: {},
      ALPHANUM19: {},
      ALPHANUM3: {},
      ALPHANUM4: {},
      ALPHANUM5: {},
      ALPHANUM6: {},
      ALPHANUM7: {},
      ALPHANUM8: {},
      ALPHANUM9: {},
      AccessType: {},
      AnnualNonCommFee: {},
      AnnualPlcyFee: {},
      AnnualPremium: {},
      AutoVehicleDriverInfo: {},
      Beneficary: {},
      BeneficaryName: {},
      Benefit: {},
      CARRIERRATEID: {},
      CarrierIDValue: {},
      CarrierName: {},
      CarrierrateOfficeID: {},
      CashSurrValue: {},
      CommAnnPrem: {},
      CommTrackFlag: {},
      ConversionEndDate: {},
      CreatedOn: {},
      DATE1: {},
      DATE10: {},
      DATE11: {},
      DATE2: {},
      DATE3: {},
      DATE4: {},
      DATE5: {},
      DATE6: {},
      DATE7: {},
      DATE8: {},
      DATE9: {},
      DataProvider: {},
      Description: {},
      DirtyFlag: {},
      DirtyTransFlag: {},
      Duration: {},
      EEUniqueId: {},
      Ex1035Prem: {},
      ExcessPrem: {},
      FYC: {},
      FlatExtra: {},
      FlatExtra1000: {},
      FlatExtraPerThousand: {},
      FlatExtraPeriod: {},
      GARATEID: {},
      GARATEOFFICEID: {},
      HasCommHierarchy: {},
      HoldingID: {},
      HoldingOfficeID: {},
      HoldingType: {},
      HoldingTypeMap: {},
      HoldingTypeText: {},
      INT1: {},
      INT2: {},
      INT3: {},
      INT4: {},
      INT5: {},
      InsuranceSyncUser: {},
      Insured: {},
      InsuredName: {},
      InvestName: {},
      IsClientHolding: {},
      IsCommFlatExtra: {},
      IsCommPolicyFee: {},
      IsGroupCertificate: {},
      IsHiddenPolicy: {},
      IssuedDate: {},
      IsyncDate: {},
      IsyncStatus: {},
      Keywords: {},
      LOOKUP1: {},
      LOOKUP2: {},
      LOOKUP3: {},
      LOOKUP4: {},
      LOOKUP5: {},
      LastActivity: {},
      LastUpdatedbySLIB: {},
      ListBill: {},
      LumpSum: {},
      MdlCommPrem: {},
      MktManager: {},
      ModalFactor: {},
      ModifiedDate: {},
      ModifiedOn: {},
      MultiLifeBusiness: {},
      MultiLifePolicy: {},
      NUM1: {},
      NUM2: {},
      NUM3: {},
      NUM4: {},
      NUM5: {},
      NUM6: {},
      Name: {},
      NonCommFee: {},
      NumberOfHoldings: {},
      OfficeCode: {},
      Owner: {},
      OwnerName: {},
      POLAnnualPremium: {},
      PaidToDate: {},
      PaidToNew: {},
      ParentIDVal: {},
      PaymentMethod: {},
      Payor: {},
      PayorName: {},
      PlanInsType: {},
      PlanName: {},
      PlanType: {},
      PolicyBasicInfo: {},
      PolicyDate: {},
      PolicyFee: {},
      PolicyInfo: {},
      PolicyNumber: {},
      PolicyStatus: {},
      PolicyStatusText: {},
      PolicyType: {},
      PortfolioName: {},
      PremModeText: {},
      PremStopDate: {},
      Premium: {},
      PremiumMode: {},
      PrimaryAgentName: {},
      Priority: {},
      Private: {},
      ProductIDVal: {},
      ProductIDValue: {},
      ProviderLastModifiedOn: {},
      ProviderOffice: {},
      Purpose: {},
      ReferralAgent: {},
      RegionCode: {},
      Renewal: {},
      RenewalDate: {},
      RiderInfo: {},
      RiskClass: {},
      SLIBJobID: {},
      SourceOfDataType: {},
      SourceOfDataTypeText: {},
      SrcAdminSystem: {},
      Stage: {},
      StageText: {},
      StatusDate: {},
      TargetAmt: {},
      TransactionRollUp: {},
      UnderWriterType: {},
      UniqueID: {},
      VisibleToAdvisor: {},
      WaivePolicyFee: {},
      WriteState: {},
      YESNO1: {},
      YESNO2: {},
      YESNO3: {},
      YESNO4: {},
      YESNO5: {},
      YESNO6: {},
      YESNO7: {},
      YESNO8: {},
      YTDPremium: {},
      Annuity: {
        AnnualInc: {},
        AnnuityCost: {},
        AnnuityForm: {},
        AnnuityRate: {},
        ClientAccount: {},
        CurrDate: {},
        CurrValue: {},
        DefVal86: {},
        DefVal88: {},
        EEContribution: {},
        EEValue: {},
        ERContribution: {},
        ERValue: {},
        Employer: {},
        Immediate: {},
        LoanAmount: {},
        LoanInt: {},
        MaturityDate: {},
        MaturityValue: {},
        PaymentsYr: {},
        PayoutAmt: {},
        PenphSend: {},
        PeriodicAmt: {},
        PurDate: {},
        Qualified: {},
        SubAcctValue: {},
        SurrValue: {},
        TaxPay: {},
        Type: {},
        Withdrawals: {},
        WtdrlsYear: {},
      },
      Contact: {
        ActivityOutcomes: {},
        AlphaKey: {},
        AlphaNum1: {},
        AlphaNum2: {},
        AppointmentsMade: {},
        CallsMade: {},
        Category: {},
        ClientType: {},
        ClientTypeText: {},
        ContactSubType: {},
        ContactType: {},
        ContactTypeText: {},
        CreatedOn: {},
        EMailPrivacy: {},
        EmployerName: {},
        FirstLastName: {},
        FirstMiddleLastName: {},
        FirstName: {},
        FullName: {},
        Greeting: {},
        HOID: {},
        LastComplActOn: {},
        LastName: {},
        LatestActivityNotes: {},
        MailPrivacy: {},
        MiddleName: {},
        ModifiedOn: {},
        NPN: {},
        Name: {},
        NextActivityOn: {},
        PhonePrivacy: {},
        PreferedComm: {},
        Private: {},
        ReferentName: {},
        Remark: {},
        ReviewDate: {},
        SmsPrivacy: {},
        Source: {},
        SourceApp: {},
        SourceOfData: {},
        SubSource: {},
        Suffix: {},
        TaxID: {},
        Territory: {},
        Title: {},
        Type: {},
        AcctMasters: {
          AcctName: {},
          AcctNumber: {},
          AcctType: {},
          AcctTypeText: {},
          AllInterstParties: {},
          AlphaNum1: {},
          AlphaNum2: {},
          AlphaNum3: {},
          AlphaNum4: {},
          Custodian: {},
          DataProvider: {},
          HowHeld: {},
          HowHeldText: {},
          Liability: {},
          ModifiedOn: {},
          NetValue: {},
          Numeric1: {},
          Numeric2: {},
          OpenAcctDate: {},
          Owner: {},
          OwnerText: {},
          PersonalAsset: {},
          PostTransaction: {},
          Purpose: {},
          QualifyAccount: {},
          QualifyAccountText: {},
          Registration: {},
          Remark: {},
          SourceOfDataType: {},
          Status: {},
          TaxId: {},
          TotalValue: {},
          UnmanagedAccount: {},
        },
        Addresses: {
          Address: {
            AddressType: {},
            AddressTypeText: {},
            City: {},
            CMPLTADDR_SINGLELINE: {},
            Country: {},
            CreatedOn: {},
            ElectronicDownload: {},
            Latitude: {},
            Line1: {},
            Line2: {},
            Line3: {},
            Line4: {},
            Longitude: {},
            ModifiedOn: {},
            MovedIn: {},
            Postal: {},
            Preferred: {},
            Primary: {},
            Remark: {},
            State: {},
            Valid: {},
            ValidatedOn: {},
          },
        },
        BillingAddress: {
          Address: {
            AddressType: {},
            AddressTypeText: {},
            City: {},
            CMPLTADDR_SINGLELINE: {},
            Country: {},
            CreatedOn: {},
            ElectronicDownload: {},
            Latitude: {},
            Line1: {},
            Line2: {},
            Line3: {},
            Line4: {},
            Longitude: {},
            ModifiedOn: {},
            MovedIn: {},
            Postal: {},
            Preferred: {},
            Primary: {},
            Remark: {},
            State: {},
            Valid: {},
            ValidatedOn: {},
          },
        },
        PreferredAddress: {
          Address: {
            AddressType: {},
            AddressTypeText: {},
            City: {},
            CMPLTADDR_SINGLELINE: {},
            Country: {},
            CreatedOn: {},
            ElectronicDownload: {},
            Latitude: {},
            Line1: {},
            Line2: {},
            Line3: {},
            Line4: {},
            Longitude: {},
            ModifiedOn: {},
            MovedIn: {},
            Postal: {},
            Preferred: {},
            Primary: {},
            Remark: {},
            State: {},
            Valid: {},
            ValidatedOn: {},
          },
        },
        Person: {
          Age: {},
          BirthPlace: {},
          Citizenship: {},
          CitizenshipText: {},
          Date10: {},
          Dob: {},
          DriverLicExp: {},
          DriverLicIssue: {},
          DriverLicNo: {},
          DriverLicState: {},
          DriverLicStateText: {},
          Gender: {},
          GenderText: {},
          HIPAACLEARANCE: {},
          HIPAADATE: {},
          HIPAA_Protected: {},
          JobTitle: {},
          Language: {},
          Marital: {},
          MaritalText: {},
          ModifiedOn: {},
          Nationality: {},
          NationalityText: {},
          Networth: {},
          NoOfChildren: {},
          Occupation: {},
          Salary: {},
          SpouseName: {},
          Tax: {},
          Tobacco: {},
          TotalIncome: {},
        },
        PreferredPhone: {
          AreaCode: {},
          BestTime: {},
          CountryCode: {},
          CreatedOn: {},
          DNC: {},
          ElectronicDownload: {},
          Extension: {},
          ModifiedOn: {},
          Number: {},
          PhoneNumber: {},
          PhoneType: {},
          PhoneTypeText: {},
          Preferred: {},
          Primary: {},
          PureNumber: {},
          Remark: {},
          ValidatedOn: {},
        },
        PreferredEmailAddress: {
          Address: {},
          BadAddress: {},
          CreatedOn: {},
          ElectronicDownload: {},
          ModifiedOn: {},
          OfficeID: {},
          Preferred: {},
          Remark: {},
          WebAddressType: {},
          WebAddressTypeText: {},
        },
      },
      Carrier: {
        ContactType: {},
        EMailPrivacy: {},
        ModifiedOn: {},
        Name: {},
        PhonePrivacy: {},
        PostalMailPrivacy: {},
        RecordType: {},
        TaxID: {},
        Addresses: {
          Address: {
            AddressType: {},
            AddressTypeText: {},
            City: {},
            CMPLTADDR_SINGLELINE: {},
            Country: {},
            CreatedOn: {},
            ElectronicDownload: {},
            Latitude: {},
            Line1: {},
            Line2: {},
            Line3: {},
            Line4: {},
            Longitude: {},
            ModifiedOn: {},
            MovedIn: {},
            Postal: {},
            Preferred: {},
            Primary: {},
            Remark: {},
            State: {},
            Valid: {},
            ValidatedOn: {},
          },
        },
        Phones: {
          Phone: {
            AreaCode: {},
            BestTime: {},
            CountryCode: {},
            CreatedOn: {},
            DNC: {},
            ElectronicDownload: {},
            Extension: {},
            ModifiedOn: {},
            Number: {},
            PhoneNumber: {},
            PhoneType: {},
            PhoneTypeText: {},
            Preferred: {},
            Primary: {},
            PureNumber: {},
            Remark: {},
            ValidatedOn: {},
          },
        },
      },
      PrimaryAdvisor: {
        ActivityOutcomes: {},
        AlphaKey: {},
        AlphaNum1: {},
        AlphaNum2: {},
        AppointmentsMade: {},
        CallsMade: {},
        Category: {},
        ClientType: {},
        ClientTypeText: {},
        ContactSubType: {},
        ContactType: {},
        ContactTypeText: {},
        CreatedOn: {},
        EMailPrivacy: {},
        EmployerName: {},
        FirstLastName: {},
        FirstMiddleLastName: {},
        FirstName: {},
        FullName: {},
        Greeting: {},
        HOID: {},
        LastComplActOn: {},
        LastName: {},
        LatestActivityNotes: {},
        MailPrivacy: {},
        MiddleName: {},
        ModifiedOn: {},
        NPN: {},
        Name: {},
        NextActivityOn: {},
        PhonePrivacy: {},
        PreferedComm: {},
        Private: {},
        ReferentName: {},
        Remark: {},
        ReviewDate: {},
        SmsPrivacy: {},
        Source: {},
        SourceApp: {},
        SourceOfData: {},
        SubSource: {},
        Suffix: {},
        TaxID: {},
        Territory: {},
        Title: {},
        Type: {},
      },
      InterestParties: {
        InterestParty: {
          Age: {},
          AppliedRiskClass: {},
          CaseModifiedOn: {},
          Contribution: {},
          CurrentRiskClass: {},
          HasImpairment: {},
          InterestPercent: {},
          Locked: {},
          MktManager: {},
          ModifiedOn: {},
          Name: {},
          OfficeCode: {},
          PartyContactID: {},
          PartyRole: {},
          PartyRoleText: {},
          PhoneEmail: {},
          PrefComm: {},
          ProductClassID: {},
          RegionCode: {},
          Relation: {},
          Remarks: {},
          RoleAsOfDate: {},
          RoleExpireDate: {},
          SaveAge: {},
          TotalPercent: {},
        },
      },
      Life: {
        APL: {},
        AdditionalDB: {},
        AmountRequired: {},
        BasicFace: {},
        CashValue: {},
        CollaterallyAssigned: {},
        ConvertibleFaceAmount: {},
        Covertible: {},
        CovertibleAge: {},
        DBOption: {},
        DateRequired: {},
        DividendCV: {},
        DividendCashValue: {},
        DividendOption: {},
        DividendPaid: {},
        FinalConversionDate: {},
        GDSinglePrem: {},
        GrossCV: {},
        GuaranteedPeriod: {},
        InitialPeriod: {},
        LastPremiumPaidAmount: {},
        LastPremiumPaidDate: {},
        LoanAmt: {},
        LoanIntPercent: {},
        LoanInterestRate: {},
        MEC: {},
        MecPrem: {},
        MinGuaranteedPrem: {},
        ModifiedOn: {},
        NetDB: {},
        OtherDividendDB: {},
        PaidUpAddition: {},
        Participating: {},
        PolicyInterestAmt: {},
        Rated: {},
        Rating: {},
        Renewal: {},
        SubAcctValue: {},
        SurrenderCV: {},
        SurrenderCharge: {},
        TargetPrem: {},
        TermRider: {},
        UndFaceAmt: {},
      },
      Product: {
        AnnualDiv: {},
        AssetClass: {},
        AssetClassText: {},
        AssetSector: {},
        Code: {},
        ContactID: {},
        ContactOfficeID: {},
        ConversionPeriod: {},
        Cusip: {},
        DisplayCode: {},
        DisplayName: {},
        EPC: {},
        FundFamily: {},
        GroupProduct: {},
        IsVariable: {},
        MaxConveAge: {},
        ModifiedOn: {},
        Name: {},
        Objective: {},
        OldCode: {},
        Parent: {},
        Price: {},
        PriceDate: {},
        PriceFactor: {},
        ProductStatus: {},
        ProductType: {},
        ProductTypeText: {},
        Purpose: {},
        Remark: {},
        SecondInsured: {},
        TaxDeferred: {},
        TermDuration: {},
        Transaction: {},
        VAProductCode: {},
        Virtual: {},
      },
      Riders: {
        Rider: {
          Code: {},
          CurrentAmt: {},
          Description: {},
          Effective: {},
          Expiration: {},
          Keywords: {},
          Mode: {},
          ModifiedOn: {},
          Period: {},
          Premium: {},
          ProductName: {},
          RiderName: {},
          Status: {},
          UpdatedDate: {},
        },
      },
    },
  };

  const structure = [
    {
      id: "root",
      name: "Resources",
      type: "resource",
      children: [
        {
          id: "smartoffice",
          name: "SmartOffice",
          type: "subresource",
          children: [
            ...mappings,
            {
              id: "create_new",
              name: "Create New Resource",
              type: "action",
              action: () => setShowInput(true),
            },
          ],
        },
      ],
    },
  ];

  const [showInput, setShowInput] = useState(false);
  const [newResourceName, setNewResourceName] = useState("");
  const [newResourceCode, setNewResourceCode] = useState("");
  const [newResourceInstructions, setNewResourceInstructions] = useState("");
  const [selectedPaths, setSelectedPaths] = useState([]);
  const [editingResource, setEditingResource] =
    useState<ResourceItemType | null>(null);

  // const sortResourceItems = (items: ResourceItemType[]): ResourceItemType[] => {
  //   return items.sort((a, b) => {
  //     if (a.type === "action") return 1;
  //     if (b.type === "action") return -1;
  //     return a.name.localeCompare(b.name);
  //   });
  // };

  const flattenSelectedPaths = (selectedPaths) => {
    return selectedPaths.map((pathArray) => pathArray.join("."));
  };

  const handleSubmitResource = (e: React.FormEvent) => {
    e.preventDefault();
    if (newResourceName && newResourceCode && selectedPaths.length > 0) {
      const values = flattenSelectedPaths(selectedPaths);
      const data = {
        name: newResourceName,
        type: "field",
        code: newResourceCode,
        values: values,
        instructions: newResourceInstructions,
      };
      if (editingResource) {
        updateMapping(editingResource.id, { id: editingResource.id, ...data });
      } else {
        createMapping(data);
      }

      resetForm();
    }
  };

  const handleDeleteResource = (itemToDelete: DataMappingType) => {
    deleteMapping(itemToDelete);
  };

  const handleEditResource = (item: ResourceItemType) => {
    setEditingResource(item);
    setNewResourceName(item.name);
    setNewResourceCode(item.code || "");
    setNewResourceInstructions(item.instructions || "");

    setSelectedPaths(
      item.values ? item.values.map((value) => value.split(".")) : []
    );

    setShowInput(true);
  };

  const resetForm = () => {
    setNewResourceName("");
    setNewResourceCode("");
    setNewResourceInstructions("");
    setSelectedPaths([]);
    setShowInput(false);
    setEditingResource(null);
  };
  const [expandedPaths, setExpandedPaths] = useState<string[][]>([]);

  const renderNestedSelection = () => {
    const renderLevel = (level: any, path: string[] = []) => {
      return (
        <ul>
          {Object.keys(level).map((key) => {
            const newPath = [...path, key];

            const isSelected = selectedPaths.some(
              (selectedPath) => selectedPath.join(".") === newPath.join(".")
            );

            const hasChildren = Object.keys(level[key]).length > 0;

            const isExpanded = expandedPaths.some(
              (expandedPath) => expandedPath.join(".") === newPath.join(".")
            );

            return (
              <li key={key}>
                <div
                  onClick={() => {
                    if (hasChildren) {
                      setExpandedPaths((prevExpandedPaths) => {
                        if (isExpanded) {
                          return prevExpandedPaths.filter(
                            (expandedPath) =>
                              expandedPath.join(".") !== newPath.join(".")
                          );
                        } else {
                          return [...prevExpandedPaths, newPath];
                        }
                      });
                    } else {
                      setSelectedPaths((prevSelectedPaths) => {
                        if (isSelected) {
                          return prevSelectedPaths.filter(
                            (selectedPath) =>
                              selectedPath.join(".") !== newPath.join(".")
                          );
                        } else if (prevSelectedPaths.length < 3) {
                          return [...prevSelectedPaths, newPath];
                        } else {
                          return prevSelectedPaths;
                        }
                      });
                    }
                  }}
                  className={`selection-item ${isSelected ? "selected" : ""} ${
                    !hasChildren ? "leaf" : ""
                  }`}
                >
                  {/* Display shape based on whether the item has children and is expanded */}
                  {hasChildren ? (isExpanded ? "▼" : "▶") : "-"} {key}
                </div>
                {/* Render nested levels if the item has children and is expanded */}
                {hasChildren && isExpanded && renderLevel(level[key], newPath)}
              </li>
            );
          })}
        </ul>
      );
    };

    return renderLevel(SmartOfficeDictionary);
  };

  return (
    <div className="rs-app">
      <ResourceStructure
        structure={structure}
        onDelete={handleDeleteResource}
        onEdit={handleEditResource}
      />
      {showInput && (
        <div className="rs-create-new">
          <form onSubmit={handleSubmitResource}>
            <input
              type="text"
              placeholder="Enter Resource Name..."
              value={newResourceName}
              onChange={(e) => setNewResourceName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Resource Code..."
              value={newResourceCode}
              onChange={(e) => setNewResourceCode(e.target.value)}
            />
            <input
              type="text"
              placeholder="(Optional) Give Fin instructions. Ex: Capitalize contact's first and last name."
              value={newResourceInstructions}
              onChange={(e) => setNewResourceInstructions(e.target.value)}
            />
            <div className="nested-selection">{renderNestedSelection()}</div>
            <div className="form-buttons">
              <button
                type="submit"
                disabled={
                  newResourceName === "" ||
                  newResourceCode === "" ||
                  selectedPaths.length === 0
                }
              >
                {editingResource ? "Update Resource" : "Add Resource"}
              </button>
              <button type="button" onClick={resetForm}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default DataMapping;
