import React, { useEffect, useState } from "react";
import { postDataMapping, getDataMappings, putDataMapping } from "../api/api";
import axios from "axios";
import { DataMapping } from "../types/types";

const useDataMapping = () => {
  const [mappings, setMappings] = useState<DataMapping[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const refresh = async () => {
    getDataMappings()
      .then((data) => {
        setMappings(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  const createMapping = (mapping) => {
    postDataMapping(mapping).then((response) => {
      refresh();
    });
  };

  const updateMapping = (mappingId: number, mapping) => {
    putDataMapping(mappingId, mapping).then((response) => {
      refresh();
    });
  };

  const deleteMapping = (mapping) => {
    if (mapping.id) {
      axios.delete(`/api/fin/data-mapping/${mapping.id}`).then(() => refresh());
    }
  };

  return {
    isLoading,
    error,
    createMapping,
    updateMapping,
    deleteMapping,
    refresh,
    mappings,
  };
};

export default useDataMapping;
