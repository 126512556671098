import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import usePostLinkedin from "../../hooks/usePostLinkedin";

import "./Social.css";

interface LinkedinModalProps {
  text: string;
  isLinkedinModalOpen: boolean;
  setIsLinkedinModalOpen: (isOpen: boolean) => void;
}

  const removeMarkdown = (text: string): string => {
    // Function to preserve list markers
    const preserveListMarkers = (line: string): string => {
      const unorderedMatch = line.match(/^(\s*[-*+]\s)/);
      const orderedMatch = line.match(/^(\s*\d+\.\s)/);
      return unorderedMatch ? unorderedMatch[1] + line.slice(unorderedMatch[1].length) :
          orderedMatch ? orderedMatch[1] + line.slice(orderedMatch[1].length) :
              line;
    };

    // Split the text into lines
    let lines = text.split('\n');

    // Process each line
    lines = lines.map(line => {
      // Remove headers, bold, italic, links, code blocks, strikethrough
      line = line.replace(/#{1,6}\s?/g, '')
          .replace(/(\*\*|__)(.*?)\1/g, '$2')
          .replace(/(\*|_)(.*?)\1/g, '$2')
          .replace(/\[(.*?)\]\(.*?\)/g, '$1')
          .replace(/`{1,3}.*?`{1,3}/g, '')
          .replace(/~~(.*?)~~/g, '$1');

      // Preserve list markers and indentation
      line = preserveListMarkers(line);

      // Remove blockquotes markers but preserve the text
      line = line.replace(/^\s*>\s?/g, '');

      return line;
    });

    // Join the lines back together
    return lines.join('\n').trim();
  };

const LinkedinModal = ({
  text,
  isLinkedinModalOpen,
  setIsLinkedinModalOpen,
}: LinkedinModalProps) => {
  const [editedText, setEditedText] = useState("");
  const { postToLinkedin } = usePostLinkedin();

  useEffect(() => {
    if (text !== null && text.length) {
      setEditedText(removeMarkdown(text));
    }
  }, [text]);

  useEffect(() => {
    if (isLinkedinModalOpen && localStorage.getItem("selectedMessageId")) {
      localStorage.removeItem("selectedMessageId");
    }
  }, [isLinkedinModalOpen]);

  const handleTextChange = (e) => {
    setEditedText(e.target.value);
  };

  const handlePost = () => {
    postToLinkedin({ content: editedText });
    setIsLinkedinModalOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={isLinkedinModalOpen}
        onClose={() => setIsLinkedinModalOpen(false)}
        closeOnClickOutside
        hasCloseButton
        width={400}
      >
        <div className="linkedin-content">
          <div className="linkedin-content-header">Post Preview</div>
          <textarea
            className="linkedin-textarea"
            onChange={handleTextChange}
            rows={5}
            value={editedText}
          />
          <div className="linkedin-post-button">
            <Button color="Secondary" size="Medium" onClick={handlePost}>
              Post
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LinkedinModal;
