import React, { useState, useEffect, useRef } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import "./Dropdown.css";

interface DropdownProps {
  displayOption: string;
  onSelect: (option: any) => void;
  options: any[];
  placeholder?: string;
  selectedValue?: any;
}

const Dropdown = ({
  displayOption,
  options,
  onSelect,
  placeholder,
  selectedValue,
}: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedValue) {
      setSelectedOption(selectedValue[displayOption]);
    } else {
      setSelectedOption("");
    }
  }, [selectedValue, displayOption]);

  useClickOutside(dropdownRef, () => setIsActive(false));

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option[displayOption]);
    onSelect(option);
    setIsActive(false);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        onClick={() => setIsActive(!isActive)}
        className="dropdown-button"
      >
        {selectedOption || placeholder}
      </button>
      <ul className={`dropdown-list ${isActive ? "active" : "inactive"}`}>
        {options.length > 0 &&
          options.map((option, i) => {
            return (
              <li key={i} onClick={() => handleOptionSelect(option)}>
                {option[displayOption]}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Dropdown;
