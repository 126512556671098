import React, { useEffect, useState } from "react";
import "./Pricing.css";
import { useLocation, useNavigate } from "react-router-dom";
import StripePricingTable from "../../features/StripePricingTable/StripePricingTable";

const BusinessPricing: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stripePricingData, setStripePricingData] = useState(null);

  useEffect(() => {
    if (!location.state) {
      navigate("/signup/account-type");
    } else {
      setStripePricingData(location.state.stripePricing);
    }
  }, [location.state]);

  return (
    <div className="fin-onboarding-container">
      <div className="fin-onboarding-wrapper">
        {stripePricingData && (
          <StripePricingTable
            type="business"
            stripePricing={stripePricingData}
          />
        )}
      </div>
    </div>
  );
};

export default BusinessPricing;
