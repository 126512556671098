// @ts-nocheck
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";
import Alert from "../../components/Alert/Alert.tsx";
import Button from "../../components/Button/Button.tsx";
import getString from "../../utils/getString.ts";

import "./AuthVerify.css";

export default function VerifyAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || null;
  const buttonRef = useRef(null);

  const [code, setCode] = useState(Array(6).fill(""));
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const refs = Array(6)
    .fill()
    .map(() => React.createRef()); // Create refs for each input box

  const handleInputChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);

    // Focus the next box after input
    if (e.target.value && index < 5) {
      refs[index + 1].current.focus();
    } else if (e.target.value && index < 6) {
      buttonRef.current.focus();
    }
  };

  const handleRequestNewCode = (e) => {
    e.preventDefault();
    axios
      .post(
        "/api/auth/new-verification-token",
        { email: email },
        { withCredentials: true },
      )
      .then(() => {
        setAlertProps({
          text: getString("emailCodeSuccess"),
          type: "success",
        });
      })
      .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  };

  const handleSubmitCode = () => {
    const codeString = code.join("");

    axios
      .post(
        "/api/auth/verify",
        { verification_token: codeString, email: email },
        { withCredentials: true },
      )
      .then(() => navigate("/u/app/fin/activity"))
      .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  };

  const handleEnter = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      handleSubmitCode();
    }
  };

  return (
    <div className="overlay">
      <div className="modal">
        <h2 className="verify-title">{getString("emailCode")}</h2>
        {alertProps.text && <Alert {...alertProps} />}
        <p>{getString("emailCodeExpiration")}</p>
        <div>
          {code.map((c, index) => (
            <input
              ref={refs[index]}
              key={index}
              data-testid={`codeInput${index}`}
              type="text"
              maxLength="1"
              value={c}
              onChange={(e) => handleInputChange(e, index)}
              className="box-input"
            />
          ))}
        </div>
        <div className="auth-verify-inner">
          <div className="request-code-container">
            <div onClick={handleRequestNewCode} className="request-code-button">
              {getString("requestCode")}
            </div>
          </div>
          <div className="auth-button-container">
            <Button
              buttonRef={buttonRef}
              color="Secondary"
              size="Medium"
              text="Submit"
              onClick={handleSubmitCode}
              onKeyDown={(e) => handleEnter(e)}
              tabIndex={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
