import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";

import "./Search.css";

const SearchComponent = ({ data, keysToSearch, renderResults, refresh }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    if (data) {
      const fuseInstance = new Fuse(data, {
        keys: keysToSearch,
        includeScore: true,
        threshold: 0.4,
      });
      setFuse(fuseInstance);
    }
  }, [data, keysToSearch]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    refresh(value);

    if (fuse) {
      const results = fuse.search(value);
      setSearchResults(results);
    }
  };

  return (
    <div className="search-wrapper">
      <div className="search-input-container">
        <ion-icon name="search-sharp"></ion-icon>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>

      {renderResults(searchQuery, searchResults)}
    </div>
  );
};

export default SearchComponent;
