import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import useFetchPortfolios from "../../hooks/useFetchPortfolios";
import { Assignment, Portfolio, Investor } from "../../types/types";
import getString from "../../utils/getString";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./Assignment.css";
import CheckboxList from "../../components/Checkbox/CheckboxList";
import Button from "../../components/Button/Button";
import { useAppContext } from "../../contexts/AppContext";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Link } from "react-router-dom";
import useInvestors from "../../hooks/useInvestors";
import { updatePlanMessage } from "../../utils/getMessage";

interface AssignmentFormProps {
  selectedAssignment: Assignment;
  assignments: Assignment[];
  onSubmit: (assignment: Assignment) => void;
}

function AssignmentForm({
  onSubmit,
  assignments,
  selectedAssignment,
}: AssignmentFormProps) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [frequency, setFrequency] = useState([]);
  // const [documentType, setDocumentType] = useState([]);
  const [assignmentType, setAssignmentType] = useState(null);
  const [selectedInvestor, setSelectedInvestor] = useState<Investor>(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState<
    Portfolio | { id: number; name: string }
  >(null);
  const [startDate, setStartDate] = useState(tomorrow);

  const initialized = useRef(false);

  const { isLoading, error, portfolios } = useFetchPortfolios();
  const { investors } = useInvestors(9999);
  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    if (initialized.current === true) {
      setSelectedPortfolio(null);
    } else {
      initialized.current = true;
    }
  }, [selectedInvestor]);

  useEffect(() => {
    if (selectedAssignment) {
      initialized.current = false;
      const investor = investors.filter(
        (investor) => investor.id === selectedAssignment.investor_id
      )[0];
      setSelectedInvestor(investor);
      if (selectedAssignment.portfolio_id) {
        const portfolio = portfolios.filter(
          (portfolio) => portfolio.id === selectedAssignment.portfolio_id
        )[0];
        setSelectedPortfolio(portfolio);
      } else {
        setSelectedPortfolio({ id: null, name: "All" });
      }
      const newStartDate = new Date(selectedAssignment.start_date);
      newStartDate.setMinutes(
        newStartDate.getMinutes() + newStartDate.getTimezoneOffset()
      );
      setStartDate(newStartDate);
      setFrequency(selectedAssignment.frequency);
      // setDocumentType(selectedAssignment.document_type);
      setAssignmentType(selectedAssignment.type);
    } else {
      resetForm();
    }
  }, [selectedAssignment]);

  if (isLoading) {
    return <div>{getString("loading")}...</div>;
  }

  if (error) {
    return <div>{getString("errorOccured")}</div>;
  }

  const isComplete =
    assignmentType &&
    // documentType.length > 0 &&
    frequency.length > 0 &&
    selectedInvestor &&
    selectedPortfolio &&
    startDate;

  const isAtLimit = assignments?.length >= user?.subscription?.assignment_limit;
  const hasPortfolios = portfolios?.length > 0;
  const isDisabled = isAtLimit || !hasPortfolios;

  const handleSubmit = () => {
    onSubmit({
      id: selectedAssignment?.id,
      user_id: user.id,
      // document_type: documentType,
      frequency: frequency,
      type: assignmentType,
      investor_id: selectedInvestor.id,
      investor_name: selectedInvestor.name,
      portfolio_id: selectedPortfolio.id,
      portfolio_name: selectedPortfolio.name,
      start_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
    });
    resetForm();
  };

  const resetForm = () => {
    setSelectedPortfolio(null);
    setSelectedInvestor(null);
    setStartDate(tomorrow);
    setFrequency([]);
    // setDocumentType([]);
    setAssignmentType(null);
  };

  const ingestDataMessage = (
    <>
      <span>{getString("ingestData")}</span>
      <Link
        className="home-sidebar-item"
        to={"/u/app/settings/profile"}
        style={{ padding: "0px" }}
      >
        {" "}
        <span style={{ color: "white", fontSize: "12px" }}>
          Connect Accounts
        </span>
      </Link>
    </>
  );

  return (
    <div className="new-assignment-form-wrapper">
      <h3 className="section-header">
        {selectedAssignment ? "Update Assignment" : "New Assignment"}
      </h3>
      <div className="section responsive">
        <div className="new-assignment-form-left-container">
          <h4 className="assignment-form-header">{getString("investor")}:</h4>
          <Dropdown
            displayOption="name"
            options={investors}
            onSelect={(investor) => {
              setSelectedInvestor(investor);
            }}
            selectedValue={selectedInvestor}
            placeholder="Select an investor..."
          />
          <h4 className="assignment-form-header">{getString("portfolio")}:</h4>
          <Dropdown
            displayOption="name"
            options={[
              { name: "All" },
              ...portfolios.filter(
                (portfolio) => portfolio.investor == selectedInvestor?.id
              ),
            ]}
            onSelect={(portfolio) => {
              setSelectedPortfolio(portfolio);
            }}
            selectedValue={selectedPortfolio}
            placeholder="Select a portfolio..."
          />
          <h4 className="assignment-form-header">
            {getString("assignmentType")}:
          </h4>
          <Dropdown
            displayOption="name"
            options={
              user?.report_types?.length > 0
                ? user.report_types.map((type) => ({
                    name: type,
                  }))
                : [{ name: "..." }]
            }
            onSelect={(value) => setAssignmentType(value.name)}
            selectedValue={assignmentType ? { name: assignmentType } : null}
            placeholder="Select an assignment..."
          />
        </div>
      </div>
      <div className="padded">
        <div className="section">
          <div>{getString("frequency")}</div>
          <CheckboxList
            options={["Daily", "Monthly", "Quarterly"]}
            selectedOptions={frequency}
            onSelect={setFrequency}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>Start date</div>
        <DatePicker selected={startDate} onChange={setStartDate} />
      </div>
      {/* <div className="padded">
        <div className="section">
          <div>{getString("documentType")}</div>
          <CheckboxList
            options={["Word Document", "PDF"]}
            selectedOptions={documentType}
            onSelect={setDocumentType}
          />
        </div>
      </div> */}
      <div className="padded">
        <em>
          * Completed assignments will be uploaded to your{" "}
          <a href="/u/app/fin/upload">Files</a> tab.
        </em>
      </div>
      <div className="padded">
        <Tooltip
          position="top"
          isHidden={!isDisabled}
          text={
            !hasPortfolios
              ? ingestDataMessage
              : updatePlanMessage(getString("assignmentLimit"), user)
          }
          minWidth="300px"
        >
          <Button
            color="Secondary"
            disabled={!isComplete || isDisabled}
            onClick={handleSubmit}
            text="Submit"
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default AssignmentForm;
