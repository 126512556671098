import React, { useState } from "react";
import useAssignments from "../../hooks/useAssignments";
import AssignmentForm from "../../features/Assignment/AssignmentForm";
import AssignmentTable from "../../features/Assignment/AssignmentTable";
import getString from "../../utils/getString";
import "../fin/Fin.css";

function Assignments() {
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const {
    assignments,
    isLoading,
    error,
    createAssignment,
    updateAssignment,
    deleteAssignment,
  } = useAssignments();

  if (isLoading) {
    return <div>{getString("loading")}...</div>;
  }

  if (error) {
    return <div>{getString("errorOccured")}</div>;
  }

  const handleSubmit = (assignment) => {
    if (selectedAssignment !== null) {
      updateAssignment(selectedAssignment.id, assignment);
    } else {
      createAssignment(assignment);
    }
    setSelectedAssignment(null);
  };

  return (
    <div className="assignments-wrapper">
      {assignments?.length > 0 ? (
        <AssignmentTable
          assignments={assignments}
          onDelete={deleteAssignment}
          onEdit={setSelectedAssignment}
        />
      ) : (
        <div>{getString("scheduleAssignment")}...</div>
      )}
      <AssignmentForm
        onSubmit={handleSubmit}
        assignments={assignments}
        selectedAssignment={selectedAssignment}
      />
    </div>
  );
}

export default Assignments;
