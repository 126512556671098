import React from "react";
import { Assignment } from "../../types/types";
import getString from "../../utils/getString";
import FilterForm from "../../components/Filter/FilterForm";
import useFilter from "../../hooks/useFilter";
import useSort from "../../hooks/useSort";
import {
  assignmentTableHeaders,
  initialFilters,
  filterConfig,
  getInvestorOptions,
  getPortfolioOptions,
  getRunDateOptions,
  STATIC_FILTERS,
  sortOptions,
} from "./AssignmentConfig";
import "./Assignment.css";

interface AssignmentTableProps {
  assignments: Assignment[];
  onDelete: (assignment: Assignment) => void;
  onEdit: (assignment: Assignment) => void;
}

function AssignmentTable({
  assignments,
  onDelete,
  onEdit,
}: AssignmentTableProps) {
  const { filteredItems, setFilter, resetFilters, filters } = useFilter(
    assignments,
    initialFilters,
    filterConfig
  );
  const { sortedItems, sortItems, sortConfig } = useSort(filteredItems);

  const inputConfig = [
    {
      name: "investor_name",
      label: "Investor",
      type: "select",
      options: getInvestorOptions(assignments),
    },
    {
      name: "portfolio_name",
      label: "Portfolio",
      type: "select",
      options: getPortfolioOptions(assignments),
    },
    {
      name: "next_run_date",
      label: "Next Run Date",
      type: "select",
      options: getRunDateOptions(assignments),
    },
    ...STATIC_FILTERS,
  ];

  return (
    <div>
      <h1>{getString("assignments")}</h1>
      <div className="assignments-container">
        <h3 className="section-header">{getString("scheduledTasks")}</h3>
        <div className="assignments-filters-wrapper">
          <div className="assignment-function-header">Filter by:</div>
          <FilterForm
            filters={filters}
            setFilter={setFilter}
            resetFilters={resetFilters}
            inputConfig={inputConfig}
            className="assignments-filters"
          />
          <div className="vertical-separator"></div>
          <label className="assignment-function-header">Sort by: </label>
          <select
            className="assignments-sort"
            onChange={(e) => sortItems(e.target.value)}
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="assignment-table-container">
          <table className="assignment-table">
            <thead>
              <tr>
                {assignmentTableHeaders.map((header, index) => {
                  return (
                    <th key={index}>
                      <div>{getString(header)}</div>
                    </th>
                  );
                })}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedItems.map((assignment, i) => {
                return (
                  <tr key={i}>
                    <td>{assignment.investor_name}</td>
                    <td>{assignment.portfolio_name}</td>
                    <td>{assignment.type}</td>
                    <td>{assignment.next_run_date}</td>
                    <td>
                      {assignment.frequency.map((type, i) => {
                        return <div key={i}>{type}</div>;
                      })}
                    </td>
                    {/* <td>
                      {assignment.document_type.map((type, i) => {
                        return <div key={i}>{type} </div>;
                      })}
                    </td> */}
                    <td>
                      <button
                        onClick={() => onEdit(assignment)}
                        className="button file-browser-button"
                      >
                        {getString("edit")}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => onDelete(assignment)}
                        className="button delete file-browser-button"
                      >
                        {getString("delete")}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AssignmentTable;
