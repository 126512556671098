/* eslint-disable */
import "./chatwindow.css";
import { useAppContext } from "../../contexts/AppContext";
import Switch from "../../components/Switch/Switch";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import useFetchChats from "../../hooks/useFetchChats";
import getString from "../../utils/getString";
import { getUsage } from "../../api/api";
import React, { useState, useRef, useEffect } from "react";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough.tsx";
import Pendo from "../../components/Pendo/Pendo.tsx";
import { getCookie } from "../../cookie.ts";
import Upload from "../../pages/fin/Upload.tsx";
import Modal from "../Modal/Modal.tsx";
import ChatBubbles from "./ChatBubbles.tsx";
import { updatePlanMessage } from "../../utils/getMessage.tsx";

const MESSAGE_TYPE = {
  GENERATED: "generated",
  USER: "user",
};

interface ChatWindowProps {}

export default ({}: ChatWindowProps) => {
  const [val, setVal] = useState("");
  const [isChatStreaming, setIsChatStreaming] = useState(false);
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [fileDropdownOpen, setFileDropdownOpen] = useState(false);
  const [isFinFilesOpen, setIsFinFilesOpen] = useState(false);
  const [additionalOptionsOpen, setAdditionalOptionsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isPiiDisabled, setIsPiiDisabled] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);

  const [isToggleOn, setIsToggleOn] = useState(false);
  const {
    state: { user, files, uploadPinnedFiles, pinnedFilenames },
    actions: { setUsage, setUploadPinnedFiles, setPinnedFilenames },
  } = useAppContext();

  useEffect(() => {
    const uploadLimit = user?.subscription?.file_upload_limit;
    const numFiles = files.length + uploadPinnedFiles.length;
    const isUploadDisabled = uploadLimit !== null && uploadLimit <= numFiles;
    setIsUploadDisabled(isUploadDisabled);
  }, [user?.subscription?.file_upload_limit, files, uploadPinnedFiles]);

  useEffect(() => {
    const piiRedactionAccess = user?.subscription?.has_pii_redaction_access;
    setIsPiiDisabled(!piiRedactionAccess);
  }, [user?.subscription?.has_pii_redaction_access]);

  const {
    registerRef,
    unregisterRef,
    currentStep,
    refs,
    nextStep,
    prevStep,
    isActive,
    setIsActive,
    handleClose,
  } = useWalkthrough();
  const stepId = "chat";
  const ref = useRef(null);

  useEffect(() => {
    registerRef(stepId, ref);
    return () => unregisterRef(stepId);
  }, [registerRef, unregisterRef, stepId]);

  const { chats, fetchChats } = useFetchChats();

  const handleNewChatMessage = async () => {
    await fetchChats();
  };

  useEffect(() => {
    if (uploadPinnedFiles.length > 0) {
      const data = new FormData();
      uploadPinnedFiles.forEach((file, i) => {
        data.append(`file-${i}`, file, file.name);
      });
      fetch(`/api/files`, {
        method: "POST",
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: data,
      });
    }
  }, [uploadPinnedFiles]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustDropdownWidth = () => {
      if (dropdownRef.current) {
        const fileNames = dropdownRef.current.querySelectorAll(
          ".file-dropdown-file-name"
        );
        let maxWidth = 250; // Start with the minimum width

        fileNames.forEach((fileNameElement) => {
          const width = (fileNameElement as HTMLElement).offsetWidth + 20; // Add some padding
          maxWidth = Math.max(maxWidth, width);
        });

        dropdownRef.current.style.width = `${Math.min(maxWidth, 400)}px`; // Limit to max-width of 400px
      }
    };

    adjustDropdownWidth();
  }, [pinnedFilenames]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const chatHistory = [
    {
      text: "Hello, I am Fin, your digital financial analyst. How can I assist you?",
      type: "received",
      message_type: MESSAGE_TYPE.USER,
    },
    // ... more messages
  ];

  const [messages, setMessages] = useState(chatHistory);

  useEffect(() => {
    const existing_chats = transformData(chats);
    if (existing_chats.length > 0) {
      setMessages(existing_chats);
    }
  }, [chats]);

  const handleEnterPress = () => {
    if (!val.trim()) {
      return; // Prevent sending empty messages
    }

    // Add the user's query as a 'sent' message
    setMessages((prevMessages) => {
      const updatedMessages = [
        ...prevMessages,
        { text: val, type: "sent", message_type: MESSAGE_TYPE.USER },
      ];
      serverside_streamer(updatedMessages); // Start streaming response after updating messages
      return updatedMessages;
    });

    setVal(""); // Clear the input after sending
  };

  const handleMouseClick = () => {};

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages change
  }, [messages]);

  const fileDropdownRef = useRef(null);
  const additionalOptionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fileDropdownRef.current &&
        !fileDropdownRef.current.contains(event.target)
      ) {
        setFileDropdownOpen(false);
      }
      if (
        additionalOptionsRef.current &&
        !additionalOptionsRef.current.contains(event.target)
      ) {
        setAdditionalOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isChatStreaming) {
      textareaRef.current?.focus();
    }
  }, [isChatStreaming]);

  const transformData = (data) => {
    return data.flatMap((item) => {
      if (item.message_type === MESSAGE_TYPE.GENERATED) {
        const generatedResponse =
          item.response +
          " Also, you can check out today's market news in your [Activity](/u/app/fin/activity) page.";
        return [
          {
            id: item.id,
            text: generatedResponse,
            type: "received",
            message_type: MESSAGE_TYPE.GENERATED,
            feedback: item.feedback,
            submitted_at: item.submitted_at,
            verification_data: item.verification_data?.provided_data,
          },
        ];
      } else {
        return [
          {
            id: item.id,
            text: item.query,
            type: "sent",
            message_type: MESSAGE_TYPE.USER,
            feedback: item.feedback,
            submitted_at: item.submitted_at,
            verification_data: item.verification_data?.provided_data,
          },
          {
            id: item.id,
            text: item.response,
            type: "received",
            message_type: MESSAGE_TYPE.USER,
            feedback: item.feedback,
            submitted_at: item.submitted_at,
            verification_data: item.verification_data?.provided_data,
          },
        ];
      }
    });
  };

  const serverside_streamer = async (updatedMessages) => {
    const placeholderTexts = ["Thinking...", "Gathering Data...", "Processing...", "Analyzing...", "Calculating..."];
    let placeholderIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    let placeholderTimer = null;

    const responseMessageIndex = updatedMessages.length;
    setIsChatStreaming(true);
    setMessages([
      ...updatedMessages,
      { text: placeholderTexts[placeholderIndex], type: "received", message_type: MESSAGE_TYPE.USER },
    ]);

    const updatePlaceholder = () => {
      if (!setIsChatStreaming) return;

      const currentText = placeholderTexts[placeholderIndex];

      if (isDeleting) {
        charIndex--;
      } else {
        charIndex++;
      }

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[responseMessageIndex] = {
          ...newMessages[responseMessageIndex],
          text: currentText.substring(0, charIndex),
        };
        return newMessages;
      });

      if (!isDeleting && charIndex === currentText.length) {
        setTimeout(() => {
          isDeleting = true;
          updatePlaceholder();
        }, 100);
      } else if (isDeleting && charIndex === 0) {
        isDeleting = false;
        placeholderIndex = (placeholderIndex + 1) % placeholderTexts.length;
        updatePlaceholder();
      } else {
        const delay = isDeleting ? 50 : 150;
        placeholderTimer = setTimeout(updatePlaceholder, delay);
      }
    };

    updatePlaceholder();

    const stopPlaceholder = () => {
      if (placeholderTimer) {
        clearTimeout(placeholderTimer);
        placeholderTimer = null;
      }
      setIsChatStreaming(false);
    };
    const removePlaceholder = () => {
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    };

    try {
      // Fetch the response from the API
      const response = await fetch("/api/fin/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          query: val,
          pii_redact: isToggleOn,
          files: pinnedFilenames,
        }),
      });

      stopPlaceholder();
      removePlaceholder(); // Remove the placeholder message


      if (!response.ok) {
        return response.json().then((err) => {
          setMessages([
            ...updatedMessages,
            {
              text:
                err.message === "limit_reached"
                  ? getString("reachedQueryLimit")
                  : getString("errorCreatingChat"),
              type: "received",
            },
          ]);
        });
      }
      getUsage().then((response) => setUsage(response));
      // Read the response as a stream of data
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        // Massage and parse the chunk of data
        const chunk = decoder.decode(value);
        if (chunk) {
          // Update the placeholder message with streaming content
          setMessages((currentMessages) => {
            let updatedMessages = [...currentMessages];
            const currentmessageindex = updatedMessages[responseMessageIndex];
            if (!currentmessageindex) {
              updatedMessages = [
                ...currentMessages,
                {
                  text: "Loading...",
                  type: "received",
                  message_type: MESSAGE_TYPE.USER,
                },
              ];
            }
            const currentContent = updatedMessages[responseMessageIndex].text;
            updatedMessages[responseMessageIndex].text =
              currentContent.includes("Loading...")
                ? chunk
                : currentContent + chunk;
            return updatedMessages;
          });
        }
      }

      setIsChatStreaming(false);
      try {
        handleNewChatMessage();
      } catch (error) {
        console.log("error refetching chats");
      }
    } catch (error) {
      console.error(error);
      setIsChatStreaming(false);
      setMessages((messages) => {
        const updatedMessages = [...messages];
        let errorMessage = "Error occurred while generating.";
        if (
          error.message.includes("NetworkError") &&
          typeof navigator !== "undefined" &&
          navigator.userAgent.toLowerCase().indexOf("firefox") > -1
        ) {
          errorMessage =
            "Your query is being processed but may require a page refresh. We recommend switching to Chrome for an optimal experience.";
        } else {
          console.log(error.message, navigator, navigator?.userAgent);
        }
        updatedMessages[responseMessageIndex].text = errorMessage;
        return updatedMessages;
      });
    }
  };

  const showFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      const newFileNames = newFiles.map((file) => file.name);

      // Filter out any files that are already pinned
      const uniqueNewFiles = newFiles.filter(
        (file) => !pinnedFilenames.includes(file.name)
      );
      const uniqueNewFileNames = newFileNames.filter(
        (name) => !pinnedFilenames.includes(name)
      );

      setUploadPinnedFiles([...uploadPinnedFiles, ...uniqueNewFiles] as any);
      setPinnedFilenames([...pinnedFilenames, ...uniqueNewFileNames]);
      setFileDropdownOpen(false);
    }
  };

  const showPendo = currentStep === 2 && refs[stepId] && isActive;
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const handleUnpinFile = (filename: string) => {
    setPinnedFilenames(pinnedFilenames.filter((name) => name !== filename));
    setUploadPinnedFiles(
      uploadPinnedFiles.filter((file) => file.name !== filename)
    );
  };

  const handleClickQFinFiles = () => {
    if (isUploadDisabled) {
      return;
    }
    setIsFinFilesOpen(true);
  };

  return (
    <div className="chat-window-wrapper">
      <div ref={ref} className="pendo-button">
        {showPendo && (
          <Pendo
            content="Chat with Fin here"
            targetRef={ref}
            next={nextStep}
            prev={prevStep}
            currentStep={currentStep}
            setIsActive={setIsActive}
            handleClose={handleClose}
          />
        )}
      </div>
      <div style={{ width: "100%" }}>
        <Modal
          isOpen={isFinFilesOpen}
          onClose={() => setIsFinFilesOpen(false)}
          closeOnClickOutside
          hasCloseButton
          width={500}
          height={200}
        >
          <Upload showDownload={false} showDelete={false} showAddToFin={true} />
        </Modal>
        <div className="slide-out-content">
          <ChatBubbles
            messages={messages}
            pinnedFilenames={pinnedFilenames}
            messagesEndRef={messagesEndRef}
          />
          <div className={`chatbox-container`}>
            <div className="chatbox-inner-container">
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                <div className="cursor-container">
                  <span className="flashing-cursor">|</span>
                </div>
                <textarea
                  ref={textareaRef}
                  className={`chatbox-container-textarea ${
                    showPendo ? "pendo-active" : ""
                  } ${isChatStreaming ? "disabled" : ""}`}
                  disabled={isChatStreaming}
                  data-testid="chatInput"
                  value={val}
                  placeholder="Ask a question"
                  onFocus={() => setIsTextareaFocused(true)}
                  onBlur={() => setIsTextareaFocused(false)}
                  onChange={(e) => setVal(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleEnterPress();
                    }
                  }}
                  onClick={handleMouseClick}
                />
              </div>
              <div className="chatbox-button-container">
                <div className="chatbox-button-line"></div>
                <div className="chatbox-buttons">
                  <div className="tooltip" ref={fileDropdownRef}>
                    <button
                      className={`chatbox-fileviewer-btn ${
                        pinnedFilenames.length > 0 ? "active" : ""
                      }`}
                      data-testid="chatboxUpload"
                      onClick={() => setFileDropdownOpen(!fileDropdownOpen)}
                    >
                      <ion-icon
                        className="chatbox-upload"
                        style={{ fontSize: "24px" }}
                        name="attach-sharp"
                      ></ion-icon>
                    </button>
                    <span className="tooltiptext-files">
                      {isUploadDisabled ? (
                        <span className="line1">
                          {user && (
                            <span>
                              {updatePlanMessage(
                                getString("uploadFilesLimit"),
                                user
                              )}
                            </span>
                          )}
                        </span>
                      ) : (
                        <>
                          <span className="line1">Upload docs to Fin</span>
                          <span className="line2">(Max 30 pages)</span>
                        </>
                      )}
                    </span>
                    {fileDropdownOpen && (
                      <div className="file-dropdown-select-options">
                        <label className="file-computer-btn">
                          <div
                            className={`file-dropdown-option ${
                              isUploadDisabled && "disabled"
                            }`}
                          >
                            Your Computer
                          </div>
                          <input
                            disabled={isUploadDisabled}
                            ref={fileInputRef}
                            type="file"
                            data-testid="chatboxUploadInput"
                            onChange={(e) => showFiles(e)}
                            multiple
                            accept=".docx,.pdf,.xls,.xlsx,.csv,.txt"
                          />
                        </label>
                        <div
                          className={`file-dropdown-option ${
                            isUploadDisabled && "disabled"
                          }`}
                          onClick={handleClickQFinFiles}
                        >
                          Q(Fin) Files
                        </div>
                        {pinnedFilenames.length > 0 && (
                          <>
                            <div className="file-dropdown-header">
                              Attached Files
                            </div>
                            {pinnedFilenames.map((filename, index) => (
                              <div key={index} className="file-dropdown-item">
                                <span
                                  className="file-dropdown-unpin-icon"
                                  onClick={() => handleUnpinFile(filename)}
                                >
                                  <ion-icon name="close-outline"></ion-icon>
                                </span>
                                <div className="file-dropdown-file-name">
                                  {filename}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="tooltip" ref={additionalOptionsRef}>
                    <button
                      onClick={() =>
                        setAdditionalOptionsOpen(!additionalOptionsOpen)
                      }
                      className="additional-options-btn"
                    >
                      <ion-icon
                        className="chatbox-upload"
                        style={{ fontSize: "24px" }}
                        name="options-sharp"
                      ></ion-icon>
                    </button>
                    <span className="tooltiptext-options">
                      Additional Options
                    </span>
                    {additionalOptionsOpen && (
                      <div className="additional-options-dropdown">
                        <div className="toggle-switch-container">
                          <label className="toggle-switch">
                            <span className="toggle-text">Redact PII</span>
                            {user && (
                              <Tooltip
                                position="top"
                                isHidden={!isPiiDisabled}
                                text={updatePlanMessage(
                                  getString("piiRedactionAccess"),
                                  user
                                )}
                                minWidth="200px"
                              >
                                <Switch
                                  disabled={isPiiDisabled}
                                  checked={isToggleOn}
                                  onChange={() => setIsToggleOn(!isToggleOn)}
                                />
                              </Tooltip>
                            )}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="chatbox-disclaimer">{getString("disclaimer")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
