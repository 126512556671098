import React from "react";

interface CheckboxListProps {
  options: string[];
  selectedOptions: string[];
  onSelect: (newOptions: string[]) => void;
}

function CheckboxList({
  options,
  selectedOptions,
  onSelect,
}: CheckboxListProps) {
  const handleSelectedChanged = (option) => {
    if (selectedOptions.includes(option)) {
      onSelect(selectedOptions.filter((item) => item !== option));
    } else {
      onSelect([...selectedOptions, option]);
    }
  };

  return (
    <div>
      {options.map((option, i) => {
        return (
          <label key={i}>
            <input
              type="checkbox"
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={() => handleSelectedChanged(option)}
            ></input>
            {option}
          </label>
        );
      })}
    </div>
  );
}

export default CheckboxList;
